<template>
  <div class="not-found-container">
    <h1>404</h1>
    <p>{{ $t("Oops! The page you're looking for doesn't exist.") }}</p>
    <a href="/">{{ $t('Return Home') }}</a>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style lang="scss" scoped>
.not-found-container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f5;
}

h1 {
  font-size: 10rem;
  color: #3b6196;
  margin: 0;
}

p {
  font-size: 1.5rem;
  color: #555;
  margin: 0.5rem 0 2rem 0;
}

img {
  width: 250px;
  height: auto;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  font-size: 1.2rem;
  color: white;
  background-color: #3b6196;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  transition: background-color 0.3s;
}

a:hover {
  background-color: #4672af;
}
</style>